import { useInfiniteQuery } from '@tanstack/react-query';
export const usePaging = (key, loadingFunction, config) => {
  const queryKey = `${key}-paged`;
  const {
    isPending,
    isError,
    data: pagedDataGroups,
    fetchNextPage,
    hasNextPage
  } = useInfiniteQuery({
    initialPageParam: '',
    queryKey: [queryKey],
    queryFn: ({
      pageParam
    }) => loadingFunction(pageParam),
    getNextPageParam: lastPage => lastPage.next,
    ...config
  });
  const list = key ? pagedDataGroups?.pages?.map(value => value.data)?.reduce((acc, data) => [...acc, ...data], []) || [] : [];
  return {
    error: isError,
    isLoading: isPending,
    data: list,
    loadMore: fetchNextPage,
    canLoadMore: !!hasNextPage
  };
};